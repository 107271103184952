import { orderBy, where } from '@firebase/firestore'
import { Payment } from 'data/stripe/StripePayment'
import { Subscription } from 'data/stripe/StripeSubscription'
import { DocumentData, FirestoreDataConverter } from 'firebase/firestore'
import { FirestoreDb } from '../components/common/Firebase'
import { Price, Product } from '../data/stripe/StripeProduct'

export const READONLY_ID_CONVERTER: FirestoreDataConverter<DocumentData> = {
  toFirestore: () => {
    throw new Error('Not implemented for readonly Product type.')
  },
  fromFirestore: (snapshot) => {
    return Object.assign(Object.assign({}, snapshot.data()), {
      id: snapshot.id,
    })
  },
}

export const PRODUCT_CONVERTER: FirestoreDataConverter<DocumentData> = {
  toFirestore: () => {
    throw new Error('Not implemented for readonly Product type.')
  },
  fromFirestore: (snapshot) => {
    return Object.assign(Object.assign({}, snapshot.data()), {
      id: snapshot.id,
      prices: [],
    })
  },
}
export const PRICE_CONVERTER: FirestoreDataConverter<DocumentData> = {
  toFirestore: () => {
    throw new Error('Not implemented for readonly Price type.')
  },
  fromFirestore: (snapshot) => {
    const data = snapshot.data()
    return Object.assign(Object.assign({}, data), {
      id: snapshot.id,
      product: snapshot.ref.parent.parent?.id,
    })
  },
}

export async function getPayments(firestoreDb: FirestoreDb, userId:string | undefined): Promise<Payment[]> {
  if (!userId) {
    return []
  }

  try {
    const querySnapshot = await firestoreDb
      .collection(`/users/${userId}/payments`)
      .query(READONLY_ID_CONVERTER, where('status', '==', 'succeeded'),orderBy('created', 'desc'))
      .docs()

    const payments = querySnapshot.data() as Payment[]

    return payments
  } catch (error) {
    console.error("Error fetching payments: ", error)
    return []
  }
}
export async function getProducts(firestoreDb: FirestoreDb): Promise<Product[]> {
  const docsSnapshot = await firestoreDb
    .collection('/products')
    .query(PRODUCT_CONVERTER, where('active', '==', true))
    .docs()

  const productsWithoutPrices = docsSnapshot.data() as Product[]
  const productsPromises: Promise<Product>[] = productsWithoutPrices.map(async (it) => {
    return {
      ...it,
      prices: await getPrices(firestoreDb, it.id),
    } satisfies Product
  })
  return Promise.all(productsPromises)
}

export async function getPrices(firestoreDb: FirestoreDb, productId: string) {
  const docs = await firestoreDb
    .collection('/products')
    .doc(`/${productId}`)
    .collection('/prices')
    .query(PRICE_CONVERTER, where('active', '==', true))
    .docs()

  return docs.data() as Price[]
}

export const getSubscriptions = async (firestoreDb: FirestoreDb, userId: string | undefined) => {
  if (!userId) {
    return
  }
  const subscriptionsSnapshot = await firestoreDb.collection(`/users/${userId}/subscriptions`).query(READONLY_ID_CONVERTER, where('status', '==', 'active'), orderBy('created', 'desc')).docs()

  const subscriptions = subscriptionsSnapshot.data() as Subscription[]
  const subscription = subscriptions.firstOrNull()
  return subscription
}
