import { FirebaseDb } from "components/common/Firebase"
import { DataSnapshot } from "firebase/database"
import { useCallback, useMemo, useState } from "react"
import { waitForDbMatch } from "util/WaitForDbMatch"
import { PendingProgramError, PendingProgramResult } from "../../functions/src/data/common"


export async function waitForPayment(
  firebaseDb: FirebaseDb,
  key: string,
  timeout = 30000,
): Promise<PendingProgramResult['result'] | PendingProgramError['error'] | undefined> {
  return waitForDbMatch(
    firebaseDb.getRef(`/pendingProgram/${key}/result`),
    (snapshot: DataSnapshot) => snapshot.exists(),
    timeout,
  )
}

export function usePaymentProgramForMerchant(firebaseDb: FirebaseDb) {
  const [isPaid, setIsPaid] = useState<boolean>(false)

  const checkPaidPlan = useCallback(
    async (merchantId: string, userId: string, productId: string) => {
      setIsPaid(true)

      const ref = await firebaseDb.getRef('/pendingProgram').push({
        userId,
        productId,
        merchantId,
      })

      let results: PendingProgramResult['result'] | PendingProgramError['error'] | undefined = undefined
      if (ref.key) {
        results = await waitForPayment(firebaseDb, ref.key, 3000)
      }

      if (results === 'success') {
        setIsPaid(false)
        return true
      }
      setIsPaid(false)
      return false

    },
    [firebaseDb],
  )

  return useMemo(() => ({ checkPaidPlan, isPaid }), [checkPaidPlan, isPaid])
}