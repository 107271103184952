
export enum ROLES {
  customer = 'customer',
  merchant = 'merchant'
}

export enum LOCAL_STORAGE {
  role = 'role'
}

export const LOCATION = {
  FREE_LOCATION_NUMBER: 1,
  LOCATION_COST: 49
}