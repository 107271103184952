import { useLoyaltyCardStore } from 'UseLoyaltyCardStore'
import { TwMainGridWrapper } from 'components/modules/twComponent/twLayout.module'
import { useMerchantData } from 'hooks/useMerchantData'
import { useContext, useEffect, useMemo } from 'react'
import 'react-circular-progressbar/dist/styles.css'
import DocumentTitle from 'react-document-title'
import { Navigate, useParams } from 'react-router-dom'
import NotificationPanel from 'ui/NotificationPanel'
import { TokenCardConfig } from '../../../functions/src/data/common'
import { useQueryString } from '../../components/QueryString'
import { useFirebase } from '../../components/common/Firebase'
import { pwaContext } from '../../components/common/NotificationRegistrationProvider'
import { useUserNullable } from '../../components/common/UseUser'
import { firebaseConfig } from '../../services/FirebaseConfig'
import { LoyaltyCardSheet } from './LoyaltyCardSheet'
import ProgramCard from 'pages/Program/ProgramCard'

const LAST_URL_KEY = 'last-url'

export function CardPage() {
  const [merchantId] = useQueryString('merchant')
  const { id: cardId } = useParams()
  const firebase = useFirebase(firebaseConfig)
  const firebaseDb = firebase.firebaseDb
  const loyaltyAuth = firebase.auth
  const { user } = useUserNullable({ auth: loyaltyAuth })
  const merchantData = useMerchantData(merchantId)

  const { notificationRegisterer } = useContext(pwaContext)
  const state = useLoyaltyCardStore(cardId || null, firebaseDb)

  const cardData = useMemo(() => {
    if (state.type === 'SUCCESS' && state.cardData) {
      const { bankType, templateId, balance, name } = state.cardData;
      const tokenBalance = bankType === 'tokens' && templateId ? balance?.[templateId] : null;

      return {
        name,
        marked: tokenBalance?.marked || 0,
        lineMax: tokenBalance?.lineMax || 0,
        claimed: tokenBalance?.claimed || 0,
      };
    }

    return { name: undefined, marked: 0, lineMax: 0 };  // Default return value to avoid undefined
  }, [state]);

  const { cardConfig } = useMemo<{
    cardConfigId?: string
    cardConfig?: TokenCardConfig
  }>(
    () =>
      (merchantData?.merchant?.cardConfigs &&
        Object.entries(merchantData?.merchant.cardConfigs)
          .mapNotNull(([cardConfigId, cardConfig]) =>
            cardConfig.cardConfigType === 'tokens' ? { cardConfigId, cardConfig } : null,
          )
          .firstOrNull()) ??
      {},
    [merchantData?.merchant?.cardConfigs],
  )

  useEffect(() => {
    if (user && merchantId === user?.uid) {
      notificationRegisterer?.requestForNotifications()
    }
  }, [user, merchantId, notificationRegisterer])


    return (
      <DocumentTitle title={'Instant Loyalty - Card'}>
        <section className='layout h-[calc(100vh-5.5rem)] bg-light-mb-main'>
          <TwMainGridWrapper className='h-full'>
            {user && merchantId && (
              <NotificationPanel
                user={user}
                firebaseDb={firebaseDb}
                merchantId={merchantId}
                className='col-span-12 hidden md:col-span-6 lg:col-span-3 lg:block'
              />
            )}
            {merchantData?.merchant.cardConfigs && cardConfig && (
              <ProgramCard
                className='col-span-12 lg:col-span-5'
                cardConfig={cardConfig}
                merchant={merchantData?.merchant}
                mode='preview'
              />
            )}

          <div className='col-span-12 h-full rounded p-4 pb-6 shadow-md xl:col-span-4 xl:bg-white'>
            <h2 className='mb-4 px-2 text-xl font-semibold'>{cardData?.name ? `${cardData.name}'s Card` : 'Reward'}</h2>
            {user === null && <Navigate to={`/merchant/${merchantId}`} />}
            {cardId && user !== null && (
              <div className='space-y-8'>
                <LoyaltyCardSheet
                  merchantId={merchantId}
                  id={cardId}
                  firebaseDb={firebaseDb}
                  user={user}
                />
              </div>
            )}
          </div>
        </TwMainGridWrapper>
      </section>
    </DocumentTitle>
  )
}
