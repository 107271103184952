import { Button } from '@chakra-ui/react'
import { useUserTeamsStore } from 'UseMerchantStore'
import { useUserCardsStore } from 'UseUserCardsStore'
import { DraggableScrollBox } from 'components/DraggableScrollBox'
import RenderIf from 'components/RenderIf'
import { useFirebase } from 'components/common/Firebase'
import { useUserNullable } from 'components/common/UseUser'
import SkeletonHome from 'components/skeletons/HomeSkeleton'
import { PATH } from 'constants/routes'
import { useMerchantData } from 'hooks/useMerchantData'
import LiveMerchantCard from 'pages/Merchant/components/LiveMerchantCard'
import { LoyaltyCardSheet } from 'pages/card/LoyaltyCardSheet'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { firebaseConfig } from 'services/FirebaseConfig'

export interface SelectedCardProps {
  merchantId: string
  cardId: string
}

const Dashboard: React.FC = () => {
  const firebase = useFirebase(firebaseConfig)
  const firebaseDb = firebase.firebaseDb
  const navigate = useNavigate()
  const { user } = useUserNullable({ auth: firebase.auth })

  const userTeams = useUserTeamsStore(user, firebaseDb)
  const state = useUserCardsStore(user, firebaseDb)

  const [selectedCardId, setSelectedCardId] = useState<SelectedCardProps>()

  const onClickSelectedCard = (info: SelectedCardProps) => {
    setSelectedCardId(info)
  }

  useEffect(() => {
    if (state.type === 'PENDING' || userTeams.type === 'PENDING') {
      return
    }
    if (userTeams.type === 'SUCCESS' && !userTeams.teams && state.type === 'NO_CARD') {
      navigate(PATH.MERCHANT_CREATE)
    }
  }, [userTeams, navigate, user, state])

  if (userTeams.type === 'PENDING' || state.type === 'PENDING') {
    return <SkeletonHome />
  }

  return (
    <section className='layout h-[calc(100vh-5.5rem)] lg:mt-6 lg:h-[80vh] max-w-[31.25rem] bg-light-mb-main lg:bg-white rounded-md'>
      <RenderIf isTrue={!!user?.uid}>
        <div className='mt-8 space-y-8 px-2.5 lg:p-4 lg:mt-2'>
          <h4 className='text-xl font-semibold capitalize'>My programs</h4>
          {state.type === 'SUCCESS' && !!Object.entries(state.cardsData).length && (
            <DraggableScrollBox direction={'horizontal'}>
              <div className='no-scrollbar flex w-full gap-3 lg:justify-center'>
                {Object.entries(state.cardsData).flatMap(([merchantId, cardRefsByConfig]) => {
                  return Object.entries(cardRefsByConfig).map(([cardConfigId, cardRef]) => ({
                    merchantId,
                    cardConfigId,
                    cardId: cardRef.cardId,
                  }))
                }).map(({ merchantId, cardId, cardConfigId }) => {
                  return (<>
                    <LiveMerchantCard
                      key={`${merchantId}-${cardConfigId}`}
                      cardId={cardId}
                      onClick={onClickSelectedCard}
                      merchantId={merchantId}
                      firebaseDb={firebaseDb}
                    />
                  </>
                  )
                })}
              </div>
            </DraggableScrollBox>
          )}
          {selectedCardId?.cardId && (
            <div>
              <LoyaltyCardSheet
                merchantId={selectedCardId?.merchantId}
                id={selectedCardId?.cardId}
                firebaseDb={firebaseDb}
                user={user}
              />
            </div>
          )}
        </div>
      </RenderIf>

      {state.type === 'NO_CARD' && (
        <div className='mt-4 text-center space-y-6'>
          <div className='flex flex-col gap-4'>
            <span>Loading Loyalty programs in your area...</span>

            <span>Scan a Loyalty QR to get started</span>
          </div>
        </div>
      )}
    </section>
  )
}

export default Dashboard
