import { ArrowRightIcon } from '@chakra-ui/icons'
import { Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/react'
import { useUserTeamsStore } from 'UseMerchantStore'
import { ReactComponent as Logo } from 'assets/img/logo.svg'
import clsx from 'clsx'
import { useFirebase } from 'components/common/Firebase'
import { useUserNullable } from 'components/common/UseUser'
import { PATH } from 'constants/routes'
import useGreeting from 'hooks/useGreeting'
import { ReactComponent as UserCircleSVG } from 'icons/account_circle.svg'
import { useCallback } from 'react'
import { FaSignOutAlt } from 'react-icons/fa'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { firebaseConfig } from 'services/FirebaseConfig'

const Header: React.FC = () => {

  const firebase = useFirebase(firebaseConfig)
  const location = useLocation()
  const loyaltyAuth = firebase.auth
  const navigate = useNavigate()


  const { user, SignIn } = useUserNullable({ auth: loyaltyAuth })

  const userTeams = useUserTeamsStore(user, firebase.firebaseDb)
  const role = userTeams.type === 'SUCCESS' ? userTeams.teams?.permissions : undefined

  const greeting = useGreeting()



  const signOut = useCallback(async () => {
    await loyaltyAuth.signOut()
  }, [loyaltyAuth])

  const onClickGotoMerchant = () => {
    if (!role) return navigate(PATH.MERCHANT_CREATE)
    return role ? navigate(PATH.MERCHANT_DASHBOARD.replace(':id', Object.keys(role)[0])) : null
  }

  return (
    <div className='shadow-sm'>
      <header className='layout hidden items-center justify-between py-6 lg:flex pr-3'>
        <div className='flex items-end justify-start gap-x-4'>
          <div className='flex items-center gap-x-2'>
            <Link to='/'>
              <Logo
                aria-label='Logo'
                className='h-5'
              />
            </Link>
          </div>
          {user && (
            <nav className='flex items-end gap-x-4 leading-3 '>
              <div className={clsx('hover:text-blue-500 text-light-secondary', { '!text-dark-main': location.pathname === '/' })}>
                <Link to='/'>
                  Home
                </Link>
              </div>
              <Link to='/about' className='text-light-secondary hover:text-blue-500'>
                Settings
              </Link>
              <Link
                to='/contact'
                className='text-light-secondary hover:text-blue-500'>
                Suport
              </Link>
            </nav>
          )}
        </div>
        {/* {user !== undefined && <SignIn />} */}
        <div>
          {user && (
            <div className='flex gap-x-4 items-center'>
              {user && <div className='flex items-center gap-x-1 hover:cursor-pointer' onClick={onClickGotoMerchant}>
                <span className='text-csm capitalize'>{role ? 'Merchant' : 'Create merchant'}</span>
                <ArrowRightIcon height={10} width={10} />
              </div>}

              <Popover>
                <PopoverTrigger>
                  <div className='flex items-center hover:cursor-pointer gap-x-1'>
                    <UserCircleSVG
                      width={30}
                      height={30}
                    />
                    <span className='text-sm font-medium'>{user?.displayName}</span>
                  </div>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverBody>
                    <div className='shadow-sm bg-white min-w-40 p-2 rounded-sm'>
                      <div onClick={signOut} className='text-sm font-medium flex items-center justify-between rounded-sm hover:cursor-pointer hover:bg-slate-200 py-0.5 w-full list-none px-2 focus-visible:!outline-none !outline-none'>
                        <span>Sign out</span>
                        <FaSignOutAlt />
                      </div>
                    </div>
                  </PopoverBody>
                </PopoverContent>
              </Popover>

            </div>
          )}
        </div>
      </header>
      <div className='hidden h-1 w-full bg-white lg:block'></div>
      <header className='layout mt-3 flex justify-between px-2.5 lg:hidden lg:px-0'>
        <div className='flex flex-col'>
          <span className='text-csm'>{greeting},</span>
          <span className='text-lg font-medium'>{user?.displayName}</span>
        </div>
        <div>
          {user?.photoURL || (
            <UserCircleSVG
              width={42}
              height={42}
            />
          )}
        </div>
      </header>
    </div>
  )
}

export default Header
