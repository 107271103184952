import { useUserTeamsStore } from 'UseMerchantStore'
import { useFirebase } from 'components/common/Firebase'
import { useUserNullable } from 'components/common/UseUser'
import MerchantAdressQRCard from 'components/modules/MerchantAdressQRCard'
import { TwMainGridWrapper } from 'components/modules/twComponent/twLayout.module'
import SkeletonHome from 'components/skeletons/HomeSkeleton'
import { useMerchantData } from 'hooks/useMerchantData'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { firebaseConfig } from 'services/FirebaseConfig'
import NotificationPanel from 'ui/NotificationPanel'
import { TokenCardConfig } from '../../../../functions/src/data/common'
import ProgramCard from '../../Program/ProgramCard'

const DashboardMerchant = () => {
  const firebase = useFirebase(firebaseConfig)
  const firebaseDb = firebase.firebaseDb
  const { id: merchantIdParams } = useParams()
  const [selectedCardConfigId, setSelectedCardConfigId] = useState<string>()

  const { user } = useUserNullable({ auth: firebase.auth })
  const userTeams = useUserTeamsStore(user, firebaseDb)

  const merchantId = useMemo(() => {
    if (userTeams.type === 'SUCCESS' && userTeams.teams) {
      return Object.keys(userTeams.teams.permissions).find(
        (merchantId) => merchantId === merchantIdParams,
      )
    }
    return undefined
  }, [userTeams, merchantIdParams])

  // Get merchant data from merchant table
  const { merchant } = useMerchantData(merchantId) || {}

  const { cardConfigId, cardConfig } = useMemo<{
    cardConfigId?: string
    cardConfig?: TokenCardConfig
  }>(
    () =>
      (merchant?.cardConfigs &&
        Object.entries(merchant.cardConfigs)
          .mapNotNull(([cardConfigId, cardConfig]) =>
            cardConfig.cardConfigType === 'tokens' ? { cardConfigId, cardConfig } : null,
          )
          .firstOrNull()) ??
      {},
    [merchant?.cardConfigs],
  )
  const addressStores =
    (!cardConfig?.isLaunched &&
      cardConfig?.locations &&
      Array(cardConfig?.locations).length > 0 &&
      cardConfig?.locations.slice(0, 3)) ||
    cardConfig?.locations


  useEffect(() => {
    if (cardConfigId) setSelectedCardConfigId(cardConfigId)
  }, [cardConfigId])

  if (!userTeams.teams || !user) {
    return <></>
  }

  if (!merchant) {
    return <SkeletonHome />
  }

  return (
    <section className='layout h-[calc(100vh-5.5rem)] max-w-[31.25rem] bg-light-mb-main'>
      <TwMainGridWrapper className='h-full'>
        <NotificationPanel
          user={user}
          firebaseDb={firebaseDb}
          merchantId={merchantId}
          className='col-span-12 hidden md:col-span-6 lg:col-span-3 lg:block'
        />

        <ProgramCard
          className='col-span-12 lg:col-span-5'
          cardConfig={cardConfig}
          merchant={merchant}
          merchantId={merchantId}
          mode={!cardConfig ? 'create' : 'preview'}
        />

        {selectedCardConfigId && merchantId && (
          <MerchantAdressQRCard
            cardConfigId={selectedCardConfigId}
            merchantId={merchantId}
            firebase={firebase}
            user={user}
            addressStore={addressStores}
          />
        )}
      </TwMainGridWrapper>
    </section>
  )
}
export default DashboardMerchant
