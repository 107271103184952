import { useLoyaltyCardStore } from 'UseLoyaltyCardStore'
import { useMerchantPrivateStore } from 'UseMerchantStore'
import { FirebaseDb } from 'components/common/Firebase'
import { User } from 'firebase/auth'
import {
  LoyaltyCard,
  PointLoyaltyCardProps,
  ScratchyLoyaltyCardProps,
  TokenLoyaltyCardProps,
} from 'pages/card/LoyaltyCard'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { UnreachableError } from 'util/error'
import checkIcon from '../../icons/crown_icon.svg'
import RedeemPointSection from './components/RewardEarnedSection'

type MessageNotification = {
  message: string
  icon: string
  title: string
  type: 'reward' | 'point'
}

export function LoyaltyCardSheet(props: {
  id: string
  merchantId: string | undefined
  firebaseDb: FirebaseDb
  user: User | undefined | null
}) {
  const tokenRef = useRef<number>(0)
  const [merchant, setMerchant] = useState(props.merchantId)
  const [messageConfirm, setMessageConfirm] = useState<MessageNotification>()
  const [markedPoints, setMarkedPoints] = useState<number>(0)

  const navigate = useNavigate()
  const state = useLoyaltyCardStore(props.id, props.firebaseDb)
  const { type: permissionResult } = useMerchantPrivateStore(merchant, props.user, props.firebaseDb)


  const balanceState = useMemo(() => {
    if (state.type === 'SUCCESS' && state.cardData) {
      let markedCaculated = markedPoints;

      const { bankType, templateId, balance, name } = state.cardData;

      if (bankType === 'tokens') {
        const tokenBalance = templateId ? balance?.[templateId] : null;

        if (tokenBalance?.marked && tokenBalance.marked > 0) {
          markedCaculated = markedPoints - tokenBalance.marked
        }

        return {
          name,
          marked: tokenBalance?.marked || 0,
          lineMax: tokenBalance?.lineMax || tokenBalance?.target || 0,
          claimed: tokenBalance?.claimed || 0,
          offers: tokenBalance?.offers,
          templateId: templateId,
          markedCaculated
        };
      }

    }
    return { name: undefined, marked: 0, lineMax: 0, templateId: undefined };  // Default return value to avoid undefined
  }, [markedPoints, state])

  const onClickClaim = useCallback((cardTypeProps: PointLoyaltyCardProps
    | TokenLoyaltyCardProps
    | ScratchyLoyaltyCardProps
    | undefined) => {
    if (cardTypeProps?.type === 'tokens' && cardTypeProps.onClaimClicked && balanceState.name) {
      const offers = Object.entries(balanceState.offers ?? {}).flatMap(([_, offer]) => ({ cost: offer.cost, label: offer.label })).firstOrNull()
      if (offers) {
        cardTypeProps.onClaimClicked(offers?.label, balanceState.templateId, offers.cost)
        setMessageConfirm({
          icon: '/images/firework.png',
          title: 'Reward Earned!',
          message: `Success! ${balanceState.name} has completed their card.`,
          type: 'reward'
        })
      }
    }
  }, [balanceState])

  const onClickRedeem = useCallback(async (cardTypeProps: TokenLoyaltyCardProps) => {
    if (cardTypeProps.onDecrementClicked && cardTypeProps.onIncrementClicked && balanceState.templateId) {
      if (markedPoints > balanceState.marked) {
        cardTypeProps.onIncrementClicked(balanceState.templateId)
      } else {
        cardTypeProps.onDecrementClicked(balanceState.templateId)
      }
      if (markedPoints === balanceState.lineMax) {
        return
      }
      setMessageConfirm({
        icon: '/images/bell.png',
        title: 'Point redeemed!',
        message: `Success! ${balanceState.name} has received ${balanceState.markedCaculated} point.`,
        type: 'point'
      })
    }
  }, [balanceState, markedPoints])

  const cardTypeProps:
    | PointLoyaltyCardProps
    | TokenLoyaltyCardProps
    | ScratchyLoyaltyCardProps
    | undefined = useMemo(() => {
      if (state.type !== 'SUCCESS') return undefined
      if (state.cardData.merchant !== merchant) setMerchant(state.cardData.merchant)
      const cardData = state.cardData
      const loyaltyType = cardData.bankType
      switch (loyaltyType) {
        case 'tokens':
          return {
            type: loyaltyType,
            bank: cardData,
            onIncrementClicked: (tokenType) =>
              state.updateTokenBalance?.(tokenType, (balance) => ({
                ...balance,
                marked: (balance.marked ?? 0) + (balanceState?.markedCaculated ?? 0),
              })),
            onDecrementClicked: (tokenType) =>
              state.updateTokenBalance?.(tokenType, (balance) => ({
                ...balance,
                marked: (balance.marked ?? 0) - (Math.abs(balanceState.markedCaculated ?? 0)),
              })),
            onClaimClicked: (offerLabel, tokenType, cost) =>
              state.updateTokenBalance?.(tokenType, (balance) => ({
                ...balance,
                marked: (balance.marked ?? 0) - cost,
                claimed: (balance.claimed ?? 0) + cost,
              })),
          } satisfies TokenLoyaltyCardProps
        case 'points':
          return {
            type: loyaltyType,
            card: cardData,

            onClaimClickConfirmed: (offerLabel, pointType, cost) =>
              state.updatePointBalance?.(pointType, (balance) => ({
                ...balance,
                points: (balance.points ?? 0) - cost,
                claimed: (balance.claimed ?? 0) + cost,
              })),
            onManualEntrySubmitted: (
              pointType: string,
              operation: 'give' | 'take',
              amount: number,
            ) => {
              if (operation === 'take') {
                state.updatePointBalance?.(pointType, (balance) => ({
                  ...balance,
                  points: (balance.points ?? 0) - amount,
                  claimed: (balance.claimed ?? 0) + amount,
                }))
              } else if (operation === 'give') {
                state.updatePointBalance?.(pointType, (balance) => ({
                  ...balance,
                  points: (balance.points ?? 0) + amount,
                }))
              }
            },
          } satisfies PointLoyaltyCardProps
        case 'scratchy':
          return {
            type: loyaltyType,
            bank: cardData,
          } satisfies ScratchyLoyaltyCardProps
        default:
          throw UnreachableError(loyaltyType)
      }
    }, [state, merchant, balanceState.markedCaculated])

  //set marked points
  useEffect(() => {
    if (state.type === 'SUCCESS') {
      if (markedPoints < balanceState.marked || balanceState.marked === 0) {
        setMarkedPoints(balanceState.marked)
      }

      if (!balanceState.marked || balanceState.marked === 0) {
        setMarkedPoints(0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, balanceState.marked]) // just run once

  useEffect(() => {
    if (state.type === 'SUCCESS' && !merchant) {
      setMerchant(state.cardData.merchant)
    }
  }, [merchant, state])

  useEffect(() => {
    if (tokenRef.current !== markedPoints) {
      setMessageConfirm(undefined)
    }
  }, [markedPoints])

  return (
    <>
      {state.type === 'SUCCESS' && cardTypeProps && balanceState.templateId && (<>
        <LoyaltyCard
          {...cardTypeProps}
          key={'loyalty-card'}
          templateId={balanceState.templateId}
          cardConfigId={state.cardData.cardConfigId}
          cardTitle={state.cardData.title ?? undefined}
          fontFamily={'LeagueSpartan'}
          theme={{ backgroundColor: 'black' }}
          logo={state.cardData.logo ?? undefined}
          markedIcon={checkIcon}
          qrUrl={`${window.location.origin}/card/${props.id}?merchant=${state.cardData.merchant}`}
          editable={permissionResult === 'SUCCESS' && state.cardData.owner !== props.user?.uid}
          setMarkedPoints={setMarkedPoints}
          markedPoints={markedPoints}
        />

        {cardTypeProps.type === 'tokens' && permissionResult === 'SUCCESS' && state.cardData.owner !== props.user?.uid && <>
          {!!balanceState.markedCaculated && markedPoints <= balanceState.lineMax && !messageConfirm && (
            <RedeemPointSection navigate={navigate}
              icon='/images/bell.png'
              title='Redeem point?'
              isConfirm={true}
              onClick={() => onClickRedeem(cardTypeProps)}
              message={`You're about to redeem ${balanceState.markedCaculated} point for ${balanceState.name}. Confirm?`}
            />
          )}

          {balanceState.marked === balanceState.lineMax && markedPoints === balanceState.marked && !messageConfirm && (
            <RedeemPointSection
              navigate={navigate}
              icon='/images/firework.png'
              title='Reward Earned!'
              isConfirm={true}
              onClick={() => onClickClaim(cardTypeProps)}
              message={`${balanceState.name} has completed their rewards card. Ready to redeem their gift?`} />
          )}

          {messageConfirm && (
            <RedeemPointSection
              navigate={navigate}
              isConfirm={false}
              icon={messageConfirm.icon}
              title={messageConfirm.title}
              message={messageConfirm.message} />
          )}
        </>}
      </>
      )}
      {(state.type === 'NO_CARD' ||
        (state.type === 'FAIL' && state.error.code === 'PERMISSION_DENIED')) &&
        props.user &&
        merchant && <Navigate to={`/merchant/${merchant}`} />}
      {state.type === 'FAIL' && state.error.code !== 'PERMISSION_DENIED' && (
        <>Something went wrong. Try again later...</>
      )}
      {state.type === 'PENDING' && <>Loading card details...</>}
      {!balanceState.templateId && <>No card found...</>}
    </>
  )
}
