import { AddIcon, DeleteIcon } from '@chakra-ui/icons'
import { useUserTeamsStore } from 'UseMerchantStore'
import Transition from 'components/Transition'
import { ButtonIcon, TwButton } from 'components/button/ButtonIcon'
import { showDialog } from 'components/common/Dialog'
import { useFirebase } from 'components/common/Firebase'
import { useUserNullable } from 'components/common/UseUser'
import CustomInput from 'components/input/CustomInput'
import { TwMainGridWrapper } from 'components/modules/twComponent/twLayout.module'
import SkeletonHome from 'components/skeletons/HomeSkeleton'
import { Product } from 'data/stripe/StripeProduct'
import { getAuth } from 'firebase/auth'
import { useMerchantData } from 'hooks/useMerchantData'
import { usePaymentProgramForMerchant } from 'hooks/usePaymentProgram'
import { ReactComponent as LocationSVG } from 'icons/location.svg'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useParams } from 'react-router-dom'
import { firebaseConfig } from 'services/FirebaseConfig'
import { IAddress } from 'types/program.module'
import NotificationPanel from 'ui/NotificationPanel'
import { getProducts } from 'util/firestoreUtil'
import { loadCheckout } from 'util/loadCheckout'
import { usePromiseState } from 'util/reactutils'
import { TokenCardConfig } from '../../../functions/src/data/common'
import ProgramCard from './ProgramCard'
import { OptionPaymentItem } from './components/OptionPaymentItem'
import PaymentSuccess from './components/PaymentSuccess'
import LocationAdditional from 'components/LocationsAdditional'
import { LOCATION } from 'constants/common'

type ProgramPaymentProps = {
  name?: string
  email?: string
  billingAddress?: string
  plan: string
  location: string
}

const ProgramPayment: React.FC = () => {
  const formRef = useRef<HTMLFormElement>(null)
  const location = useLocation()
  const { merchantId } = useParams()
  const searchParams = new URLSearchParams(location.search)
  const successParam = searchParams.get('success')
  const canceledParam = searchParams.get('canceled')
  const productParam = searchParams.get('product')

  const firebase = useFirebase(firebaseConfig)
  const firebaseDb = firebase.firebaseDb
  const { user } = useUserNullable({ auth: firebase.auth })
  const merchantData = useMerchantData(merchantId)
  const userTeams = useUserTeamsStore(user, firebaseDb)
  const products = usePromiseState(
    async () => getProducts(firebase.firestoreDb),
    [firebase.firestoreDb],
  )
  const { isPaid, checkPaidPlan } = usePaymentProgramForMerchant(firebaseDb)
  // const payments = usePromiseState(async () => getPayments(firebase.firestoreDb, user?.uid ), [firebase.firestoreDb, user?.uid])

  const productsData = useMemo(
    () =>
      products?.filter(
        (item) => item.metadata && !Object.keys(item.metadata).includes('locations'),
      ),
    [products],
  )
  const locationPrices = useMemo(
    () =>
      products
        ?.filter((item) => item.metadata && Object.keys(item.metadata).includes('locations'))
        .firstOrNull(),
    [products],
  )

  const [selectedPlan, setSelectedPlan] = useState<Product | null>(
    (products && products?.length > 0 && products?.firstOrNull()) || null,
  )
  const [locations, setLocaltions] = useState<IAddress[]>([])
  const [countAdditionalLocations, setCountAdditionalLocations] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [isPaymentSuccess, setIsPaymentSuccess] = useState<boolean>(false)
  const [errorPlan, setErrorPlan] = useState<string | null>(null)

  const {
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
    getValues,
    setValue,
    setError,
  } = useForm<ProgramPaymentProps>()

  const { cardConfig } = useMemo<{
    cardConfigId?: string
    cardConfig?: TokenCardConfig
  }>(
    () =>
      (merchantData?.merchant?.cardConfigs &&
        Object.entries(merchantData?.merchant.cardConfigs)
          .mapNotNull(([cardConfigId, cardConfig]) =>
            cardConfig.cardConfigType === 'tokens' ? { cardConfigId, cardConfig } : null,
          )
          .firstOrNull()) ??
      {},
    [merchantData?.merchant.cardConfigs],
  )

  const onSubmit = handleSubmit(async (data) => {
    if (!selectedPlan) {
      setErrorPlan('Please select a plan')
      return
    }
    const totalPayment =
      (selectedPlan?.prices?.firstOrNull()?.unit_amount ?? 0) / 100 + countAdditionalLocations * LOCATION.LOCATION_COST
    const price = selectedPlan?.prices.firstOrNull()

    const paymentBody = {
      name: data.name,
      email: data.email,
      billingAddress: data.billingAddress,
      plan: selectedPlan?.id,
      unit_amount: totalPayment,
      countAdditionalLocations: countAdditionalLocations,
    }

    if (user && paymentBody.unit_amount && price) {
      const lineItems = [
        {
          price: price.id,
          quantity: 1,
        },
      ]

      if (countAdditionalLocations > 0 && locationPrices) {
        const locationPriceId = locationPrices.prices.firstOrNull()?.id
        if (locationPriceId) {
          lineItems.push({
            price: locationPriceId,
            quantity: countAdditionalLocations,
          })
        }
      }

      showDialog({
        title: 'Payment Confirmation',
        children: `Are you sure you want to proceed with the payment of $${paymentBody.unit_amount}?`,
        positiveButtonProps: {
          text: 'Payment',
          onClicked: async () => {
            await loadCheckout({
              firestoreDb: firebase.firestoreDb,
              price: price,
              user: user,
              metadata: paymentBody,
              lineItems: lineItems,
            })
            return true
          },
        },
      })
    }
    return null
  })

  const handleClearLocation = (e: React.MouseEvent<HTMLButtonElement>, id: number) => {
    e.preventDefault()
    if (locations.length > 0) {
      const paidLocations = locations.find((item) => item.id === id && item.isPaid)
      if (paidLocations) {
        showDialog({
          title: 'Location is paid',
          children: 'This location is paid, you cannot remove it!',
          positiveButtonProps: {
            text: 'OK',
            onClicked: () => {
              return true
            }
          },
        })
        return
      }
      const newAdress = locations.filter((item) => item.id !== id)
      const unpaidLocations = newAdress.filter((item) => !item.isPaid)
      setCountAdditionalLocations(unpaidLocations.length)
      setLocaltions(newAdress)
    }
  }

  const handleAddLocation = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      const newLocation = getValues('location')
      const isExisted =
        newLocation &&
        locations.find(
          (item) => item.address.toLocaleLowerCase() === newLocation.toLocaleLowerCase(),
        )

      if (isExisted) {
        setError('location', { type: 'value', message: 'Store location is already existed!' })
        return
      }

      if (newLocation) {
        const lastItem = locations[locations.length - 1]
        const newId = lastItem ? lastItem.id + 1 : 1
        const mergeAddress = [
          ...locations,
          {
            id: newId,
            address: newLocation,
            isPaid: false,
          },
        ] as IAddress[]

        const unpaidLocations = mergeAddress.filter((item) => !item.isPaid)

        setCountAdditionalLocations(unpaidLocations.length)
        setLocaltions(mergeAddress)
        clearErrors('location')
        setValue('location', '')
      } else {
        setError('location', { type: 'value', message: 'Store location is empty!' })
      }
    },
    [locations, clearErrors, getValues, setError, setValue],
  )

  const metaExtra = useMemo(() => {
    return selectedPlan?.metadata && Object.keys(selectedPlan.metadata).length > 0 ?
      `for ${Object.values(selectedPlan.metadata).firstOrNull()} digital cards`
      : selectedPlan?.name
  }, [selectedPlan])

  useEffect(() => {
    if (user) {
      setValue('name', user.displayName ?? undefined)
      setValue('email', user.email ?? undefined)
      if (!successParam) {
        setLoading(false)
      }
    }

    if (cardConfig && cardConfig.locations) {
      const locations = cardConfig.locations.map((item, index) => ({
        ...item,
        isPaid: true,
      }))
      setLocaltions(locations)
    }
  }, [cardConfig, setValue, successParam, user])

  useEffect(() => {
    const auth = getAuth()

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (!user) {
        return
      }
      try {
        if (merchantId && user.uid && productParam) {
          const result = await checkPaidPlan(merchantId, user.uid, productParam)
          if (!isPaid && result) {
            const timeoutId = setTimeout(() => {
              setLoading(false)
              setIsPaymentSuccess(true)
            }, 3000)

            // Clean up the timeout if component unmounts or effect re-runs
            return () => clearTimeout(timeoutId)
          } else {
            setLoading(false)
            setIsPaymentSuccess(false)
          }
        }
      } catch (error) {
        console.error('Error checking payment:', error)
        setLoading(false)
        setIsPaymentSuccess(false)
      }
    })
    return () => unsubscribe()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantId, productParam, checkPaidPlan]) // don't add checkPaidPlan to the dependency array

  if (!userTeams.teams && !user && !merchantId) {
    return <></>
  }

  if (loading) {
    return <SkeletonHome />
  }

  return (
    <section className='layout h-[calc(100vh-5.5rem)] bg-light-mb-main'>
      <TwMainGridWrapper className='h-full'>
        <NotificationPanel
          user={user || undefined}
          firebaseDb={firebaseDb}
          merchantId={merchantId}
          className='col-span-12 hidden md:col-span-6 lg:col-span-3 lg:block'
        />

        <ProgramCard
          className='col-span-12 lg:col-span-5'
          cardConfig={cardConfig}
          merchant={merchantData?.merchant}
          merchantId={merchantId}
          isPayment={true}
          mode='preview'
        />

        {(isPaymentSuccess && successParam && user?.email && (
          <PaymentSuccess email={user.email} />
        )) || (
            <div
              className='animate-fade-right animate-once animate-ease-in relative col-span-12 box-content space-y-4
              overflow-y-auto rounded bg-white shadow-md lg:col-span-4'>
              <div className='sticky top-0 z-10 flex items-start justify-between bg-white p-4'>
                <div className='space-y-2'>
                  <h2 className='animate-fade-right animate-once animate-ease-in text-xl font-semibold'>
                    Launch your program
                  </h2>
                  <p className='text-light-secondary'>
                    To launch your loyalty program, please proceed to payment
                  </p>
                </div>
              </div>
              <div className='p-4 pt-0'>
                <form
                  ref={formRef}
                  onSubmit={onSubmit}
                  className='pb-4'>
                  <div className='lg:space-x-6'>
                    <div className='col-span-2 space-y-4'>
                      <CustomInput
                        label='Full name'
                        name='name'
                        type='text'
                        error={errors.name}
                        control={control}
                        rules={{ required: 'Name your program is required' }}
                      />

                      <CustomInput
                        label='Email Address'
                        name='email'
                        type='text'
                        error={errors.email}
                        control={control}
                        rules={{ required: 'Email is required' }}
                      />

                      <CustomInput
                        label='Billing Address'
                        name='billingAddress'
                        type='text'
                        error={errors.billingAddress}
                        control={control}
                        rules={{ required: 'Billiing is required' }}
                      />

                      <div className='space-y-4'>
                        {productsData?.map((item) => {
                          const extra =
                            item.metadata && Object.keys(item.metadata).length > 0 ?
                              `for ${Object.values(item.metadata).firstOrNull()} digital cards`
                              : item.name
                          return (
                            <OptionPaymentItem
                              key={item.id}
                              price={(item.prices.firstOrNull()?.unit_amount ?? 0) / 100}
                              title={item.name}
                              description={item.description}
                              extra={extra}
                              onClick={() => {setSelectedPlan(item), setErrorPlan(null)}}
                              isActive={selectedPlan?.id === item.id}
                            />
                          )
                        })}
                        {errorPlan && (
                          <span className='text-xs italic text-red-500'>{errorPlan}</span>
                        )}
                      </div>

                      <div className='w-auto space-y-3 text-sm'>
                        <p className='text-[1.35rem] font-semibold'>Extras Locations</p>
                        <p className='text-gray-500'>
                          Whether you choose the One-time Payment Plan or the Pay-As-You-Go Plan, you
                          can easily expand your program to cover more business locations.
                        </p>
                        <ul className='list-disc pl-6 text-gray-500'>
                          <li>
                            <b>Included:</b> {LOCATION.FREE_LOCATION_NUMBER} location
                          </li>
                          <li>
                            <b>Extra locations:</b> ${LOCATION.LOCATION_COST} per additional location
                          </li>
                        </ul>
                      </div>

                      <div className='relative'>
                        <LocationAdditional
                          locations={locations}
                          name='location'
                          onClick={handleClearLocation}
                          addLocations={handleAddLocation}
                          errors={errors.location}
                          control={control}
                        />

                      </div>
                      {locations.length > LOCATION.FREE_LOCATION_NUMBER && countAdditionalLocations > 0 && (
                        <Transition>
                          <OptionPaymentItem
                            className='px-3'
                            price={countAdditionalLocations * LOCATION.LOCATION_COST}
                            title='Additional Locations'
                            description={
                              <>
                                <span>{`${LOCATION.FREE_LOCATION_NUMBER}x Included Locations (free)`}</span>
                                <span>{`2x Additional Locations added ($${LOCATION.LOCATION_COST} each)`}</span>
                              </>
                            }
                            isSelectIcon={false}
                          />
                        </Transition>
                      )}

                      {selectedPlan && (
                        <>
                          <div className='space-y-4'>
                            <p className='py-2 text-[1.35rem] font-semibold'>Summary</p>
                            <div>
                              <div className='flex items-center justify-between font-semibold'>
                                <p>Plan selected</p>
                                <p>${(selectedPlan.prices.firstOrNull()?.unit_amount ?? 0) / 100}</p>
                              </div>
                              <p className='text-sm text-gray-500'>{metaExtra}</p>
                            </div>
                            {countAdditionalLocations > 0 && (
                              <Transition>
                                <div className='flex items-center justify-between font-semibold'>
                                  <p>Additional locations</p>
                                  <p>{`$${countAdditionalLocations * LOCATION.LOCATION_COST}`}</p>
                                </div>
                                <div className='text-sm'>
                                  <p className='text-gray-500'>{`${countAdditionalLocations}x Additional locations added`}</p>
                                  <p className='text-gray-500'>{`${LOCATION.FREE_LOCATION_NUMBER}x included locations (free)`}</p>
                                </div>
                              </Transition>
                            )}
                          </div>
                          <div>
                            <div className='h-0.5 w-full bg-gray-400' />
                            <div className='flex items-center justify-between py-2 text-2xl font-bold'>
                              <p>Total:</p>
                              <p>{`$${(selectedPlan.prices.firstOrNull()?.unit_amount ?? 0) / 100 + countAdditionalLocations * LOCATION.LOCATION_COST}`}</p>
                            </div>
                            <div className='h-0.5 w-full bg-gray-400' />
                            <p className='mt-3 text-sm text-gray-500'>
                              By clicking the “Proceed to Payment” button below you agree to our Terms
                              of Service and Privacy Policy.
                            </p>
                          </div>
                        </>
                      )}
                      <div className='flex justify-end gap-x-4 pt-4'>
                        <ButtonIcon
                          type='submit'
                          content='Proceed to Payment'
                          className='min-w-28 bg-light-primary text-white hover:brightness-110'
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
      </TwMainGridWrapper>
    </section>
  )
}

export default ProgramPayment
